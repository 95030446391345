import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import ResourceTags from './ResourceTags';
import { getColor, createUrl, getFormattedDate, isParamPresentInURL, checkCorporateEmail } from "../utils/universalHelperFunctions";
import BlogAuthor from '../components/BlogAuthor';
import LoginEnablingBlock from '../components/SignUpRestriction';
import '../styles/resource-overview.css';
import '../styles/blog-authors.css';

class ResourceOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shareUrl: '',
      winLocPath: '',
      fromActiveCampaign: false,
      isSubscribed: false,
      isBlockerDisplayed: false,
    };
    // setting resource data in resourceOverviewData from Slice Zone.
    this.resourceOverviewData = props.input;
    this.authorObj = '';
    this.authorObjCaseStudy = '';
    this.authorObjCaseStudyArray = '';
    this.downloadDocument = this.downloadDocument.bind(this);
    this.handleTwitterSocialMediaClick = this.handleTwitterSocialMediaClick.bind(this);
    this.handleLinkedInSocialMediaClick = this.handleLinkedInSocialMediaClick.bind(this);
    this.handleFacebookClick = this.handleFacebookClick.bind(this);
  }
  componentDidMount() {
    this.setState({ shareUrl: window.location.href });
    
    this.setState({ winLocPath: window.location.pathname === '/resources/white-papers/6-ways-to-increase-salesforce-commerce-cloud-performance/' || window.location.pathname === '/resources/white-papers/12-ways-to-reduce-shopping-cart-abandonment-in-salesforce-commerce-cloud/' ? true : false });

    let filter = localStorage.getItem('resourceFilter');
    if (filter && filter.length > 0) {
      localStorage.setItem('resourceFlag', 'true');
    }
    if (localStorage.getItem('isSubscribed')) {
      console.log('isSubscribed: ', )
      this.setState({ isSubscribed: Boolean(localStorage.getItem('isSubscribed')) })
    }

    let searchWord = localStorage.getItem('searchWord');
    if (searchWord && searchWord.length > 0) {
      localStorage.setItem('searchWordFlag', 'true');
    }

    let resourceTag = localStorage.getItem('resourceTag');
    if (resourceTag && resourceTag.length > 0) {
      localStorage.setItem('resourceTagFlag', 'true');
    }

    let url = window.location.href || '';
    if ((this.resourceOverviewData.primary.resource_type === 'Case Study' || this.resourceOverviewData.primary.resource_type === 'White Paper') && isParamPresentInURL('id', url)) {
      this.setState({ fromActiveCampaign: true });
      let urlParams = new URLSearchParams(window.location.search);
      let value = urlParams.get('id');
      checkCorporateEmail(value)
        .then(response => {
          if (response.valid) {
            this.setState({
              fromActiveCampaign: true
            });
          } else {
            this.setState({
              fromActiveCampaign: false
            })
          }
        });
      this.setState({
        fromActiveCampaign: true
      });
    }
  }

  componentDidUpdate() {
    this.state.isSubscribed && localStorage.setItem('isSubscribed', true);
  }

  downloadDocument(url, fileName) {
    var x = new XMLHttpRequest();
    x.open("GET", url, true);
    x.responseType = 'blob';
    x.accessControlAllowOrigin = '*';
    x.onload = function (e) {
      download(document.getElementById('slice-container'))
      download(x.response, fileName, "application/pdf");
    }
    x.send();
    window.open(url, '_blank');
  }

  handleFacebookClick = (e) => {
    e.preventDefault();
    let url = 'https://www.facebook.com/sharer.php?display=popup&u=' + this.state.shareUrl;
    window.open(url);
  }

  handleLinkedInSocialMediaClick = (e) => {
    e.preventDefault();
    let source = typeof window !== 'undefined' ? window.location.hostname : 'Gspann';
    let url = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(this.state.shareUrl)}&title=${encodeURIComponent(this.props.pageHeroData.items[0].hero_multiline_description.text)}&source=${encodeURIComponent(source)}`;
    window.open(url);
  }

  handleTwitterSocialMediaClick = (e) => {
    e.preventDefault();
    let url = `https://twitter.com/share?url=${encodeURIComponent(this.state.shareUrl)}&text=${encodeURIComponent(this.props.pageHeroData.items[0].hero_description.text)}`;
    window.open(url);
  }

  onLogin = () => {
    this.setState(Object.assign({ ...this.state }, { isSubscribed: true }))
  }

  onBlockerDisplay = () => {
    this.setState(Object.assign({ ...this.state }, { isBlockerDisplayed: true }))
  }

  render() {
    const resource_type = {
      'Blog': 'Blogs',
      'Case Study': 'Case Studies',
      'Event': 'Events',
      'White Paper': 'White Papers'
    }
    const overviewObj = this.resourceOverviewData.primary;
    let id = "";
    if (overviewObj.hasOwnProperty("breadcrumb_id")) {
      id = overviewObj.breadcrumb_id.text;
    }

    let url = typeof window !== 'undefined' ? window.location.pathname : '';
    let campagin = false;
    if (url.split('/')[1] === 'campaign') {
      campagin = true;
    }

    const correctDateformat = new Date(overviewObj.date)
      .toString()
      .split(' ')
      .slice(1, 4)
      .join(' ');

    // let shareUrl = window.location.href;
    if (['Blog', 'White Paper', 'Event'].includes(overviewObj.resource_type)) {
      this.authorObj = this.props.author.primary;
    } else if (['Case Study'].includes(overviewObj.resource_type)) {
      this.authorObjCaseStudy = this.props.author.primary;
    }

    let inlineStyle = {};

    if (overviewObj.resource_type === 'Blog' || overviewObj.resource_type === 'Case Study' || overviewObj.resource_type === 'White Paper'|| (overviewObj.resource_type === 'Event' && this.authorObj?.author_name.text.length>0)) {
      inlineStyle = {
        display: 'inline',
        paddingRight: 0
      }
    } else {
      if (overviewObj.resource_type === 'Blog Overview'|| (overviewObj.resource_type === 'Event')) {
        inlineStyle = {
          // visibility: 'hidden',
          paddingRight: 0
        }
      } else {
        inlineStyle = {
          display: 'none',
          paddingRight: 0
        }
      }
    }
    let resource_details_spacing = "";
    if (overviewObj.resource_type === 'Case Study' || overviewObj.resource_type === 'White Paper' || overviewObj.resource_type === 'Blog' || overviewObj.resource_type === 'Blog Overview'|| (overviewObj.resource_type === 'Event')) {
      if (this.state.fromActiveCampaign) {
        resource_details_spacing = ' container-spacing active-campaign-spacing';
      } else {
        resource_details_spacing = ' container-spacing';
      }
    }
    let gridSize = 12;
    let isNewDesign = process.env.NEW_CASE_STUDY_DESIGN && process.env.NEW_CASE_STUDY_DESIGN === 'true' ? true : false;
    let allowedResourceType = overviewObj.resource_type === 'Blog' || overviewObj.resource_type === 'Blog Overview'|| (overviewObj.resource_type === 'Event');
    if (allowedResourceType) {
      gridSize = 9;
    } else {
      allowedResourceType = overviewObj.resource_type === 'Case Study' || overviewObj.resource_type === 'White Paper';
      if (allowedResourceType) {
        gridSize = 9;
      } else {
        if (overviewObj.resource_type !== 'Event' && !this.state.fromActiveCampaign) {
          gridSize = 7;
        }
      }
    }
    //reverting new case study design, uncomment the changes
    let highlighterFontColor = '#000';
    let highlighterColor = '#000';
    let highlighterSection = null;
    if (overviewObj.hasOwnProperty('highlighter_font_color') && overviewObj.highlighter_font_color) {
      highlighterFontColor = getColor(overviewObj.highlighter_font_color);
    }
    if (overviewObj.hasOwnProperty('highlighter_color') && overviewObj.highlighter_color) {
      highlighterColor = getColor(overviewObj.highlighter_color);
    }

    if (overviewObj.highlighter_content && overviewObj.highlighter_content.html) {
      highlighterSection = (
        <div className="highligher-border" style={{ borderColor: highlighterColor }}>
          {
            overviewObj.highlighter_title && overviewObj.highlighter_title.text && (
              <h2 className="highlighter-title">{overviewObj.highlighter_title.text}</h2>
            )
          }
          <div
            style={{ color: highlighterFontColor }}
            className={`highlighter-content`}
            dangerouslySetInnerHTML={{
              __html:
                overviewObj.highlighter_content.raw.length > 1 ? overviewObj.highlighter_content.html : overviewObj.highlighter_content.raw[0].text,
            }}
          />
        </div>
      )
    }

    let displayText = '';
    let overivewContent = overviewObj.overview.raw ? overviewObj.overview.raw.length > 1 ? overviewObj.overview.html : overviewObj.overview.raw[0].text : overviewObj.overview.html;
    const isContentBlocked = overivewContent?.includes('<div id="contentBlocker"></div>');
    if (this.state.isSubscribed) {
      displayText = overivewContent;
    } else {
      displayText = overivewContent.split('<div id="contentBlocker"></div>')[0];
    }
    console.log('this.props.isHidden', this.props.isHidden);
    console.log('!this.state.isSubscribed && this.props.isHidden', !this.state.isSubscribed && this.props.isHidden);
    return (
      ((this.props.input.primary.is_protected && this.state.isSubscribed) || !this.props.input.primary.is_protected) && <div className="slice-width-global" id={id}>
        {this.renderPageSeoSchema()}
        <Container className={`overview-container ${allowedResourceType ? 'blog-overivew-container' : ''}`}>

          {/* Setting overview HTML Details */}
          <Row noGutters className="reverse-view-for-mobile">
            <Col xs="12" md={gridSize} className="overview-description-container section-spacing-for-mobile">
              {
                overviewObj.highlighter_position && overviewObj.highlighter_position === 'Top' && overviewObj.highlighter_content && overviewObj.highlighter_content.html && (
                  <div style={{ marginBottom: '40px' }}>{highlighterSection}</div>
                )
              }
              {
                overviewObj?.title?.text &&
                <h2 className="overview-title">
                  {overviewObj.title.text}
                </h2>
              }
              <div
                className={`overview-details ${resource_details_spacing} ${allowedResourceType ? 'blog-overview-details' : ''}`}
                dangerouslySetInnerHTML={{
                  __html:
                    displayText,
                }}
              //{...overviewObj.overview.raw[0].text.includes("contentBlocker")}
              />
              {
                overviewObj.highlighter_position && overviewObj.highlighter_position !== 'Top' && overviewObj.highlighter_content && overviewObj.highlighter_content.html && (
                  <div style={{ marginTop: '40px' }}>{highlighterSection}</div>
                )
              }
              {(!this.state.isSubscribed && isContentBlocked) && < LoginEnablingBlock onLogin={this.onLogin} onBlockerDisplay = {this.onBlockerDisplay} />}
            </Col>
            <Col xs="12" md={3} style={inlineStyle}>
              <Row className="container-spacing" noGutters>
                {
                  isNewDesign && this.authorObj && (
                    <Col md="12">
                      <div id="author-info">
                        <section className='ui-authors'>
                          {[
                            this.authorObj,
                            ...(this.props?.author?.items ?? [])
                          ]
                            .map((author, idx) => <BlogAuthor key={idx} author={author} />)
                          }
                        </section>
                        { (!this.state.winLocPath) && <p className="published-date" rel={correctDateformat} style={{ fontSize: '12px', marginBottom: '0', marginTop: '20px', color: '#7F8C8D' }}>
                          {`Published ${correctDateformat}`}
                        </p>
                        }
                        <div className="share-documents-container">
                          <a
                            href=''
                            target="_blank"
                            onClick={this.handleFacebookClick}
                            hrefLang="en"
                          >
                            <i className="fab fa-facebook-square social-media-icons"></i>
                          </a>
                          <a
                            href=''
                            target="_blank"
                            onClick={this.handleLinkedInSocialMediaClick}
                            hrefLang="en"
                          >
                            <i className="fab fa-linkedin ml-2 social-media-icons"></i>
                          </a>
                          <a
                            href=''
                            target="_blank"
                            onClick={this.handleTwitterSocialMediaClick}
                            hrefLang="en"
                          >
                            <i className="fab fa-square-x-twitter ml-2 social-media-icons"></i>
                          </a>
                        </div>
                      </div>
                    </Col>
                  )
                }
                
                {/* <Col md="12" className='authors-casestudy' >
                  <section id="authors-casestudy">
                    {[this.authorObjCaseStudyArray].map((author, idx) => <BlogAuthor key={idx} author={author} />)
                    }
                  </section>
                </Col> */}
                {/* < LoginEnablingBlock /> */}
                {
                  isNewDesign && this.authorObjCaseStudy && overviewObj.resource_type && (overviewObj.resource_type === "Case Study" || overviewObj.resource_type === "White Paper") && (
                    <Col md="12">
                      <div id="author-info">
                        <section className='ui-authors'>
                          {[
                            this.authorObjCaseStudy,
                            ...(this.props?.author?.items ?? [])
                          ]
                            .map((author, idx) => <BlogAuthor key={idx} author={author} />)
                          }
                        </section>
                        <div className="share-documents-container">
                          <div className="share-document-heading"> Share this Document</div>
                          <div>
                            <a
                              href=''
                              target="_blank"
                              onClick={this.handleFacebookClick}
                              hrefLang="en"
                            >
                              <i className="fab fa-facebook-square social-media-icons"></i>
                            </a>
                            <a
                              href=''
                              target="_blank"
                              onClick={this.handleLinkedInSocialMediaClick}
                              hrefLang="en"
                            >
                              <i className="fab fa-linkedin ml-2 social-media-icons"></i>
                            </a>
                            <a
                              href=''
                              target="_blank"
                              onClick={this.handleTwitterSocialMediaClick}
                              hrefLang="en"
                            >
                              <i className="fab fa-square-x-twitter ml-2 social-media-icons"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      {
                        overviewObj.document_download_link && overviewObj.document_download_link.url ? (
                          <div className="document-btn-wrapper">
                            <a className="share-document-btn" onClick={e => this.downloadDocument(overviewObj.document_download_link.url, overviewObj.downloadable_document_name.text)}>
                              <i className="fas fa-download downlaod-icon"></i>
                              <span className="share-doc-link"> Download {overviewObj.resource_type}</span>
                            </a>
                          </div>
                        ) : ''
                      }

                    </Col>
                  )
                }
                <Col className="resource-overview-tag-col">
                  {
                    (isNewDesign) && this.props.tagsData && this.props.tagsData.length > 0 ? <div>
                      <ResourceTags
                        pageId={this.props.pageId}
                        input={this.props.tagInput}
                        tagsData={this.props.tagsData}
                        pagesLinksData={this.props.pagesLinksData}
                        resourceType={overviewObj.resource_type}
                        classNameData='resource-overview-tag'
                      /></div> : ''
                  }
                </Col>
                <Col md="12" className="visibleForDesktop related-blog-wrapper">
                  {
                    this.props.relatedBlogs && this.props.relatedBlogs.items && this.props.relatedBlogs.items.length > 0 && (
                      <div>
                        <div className="related-blogs-heading">{overviewObj.resource_type && overviewObj.resource_type === 'White Paper' ? 'Related Resources' : `Related ${resource_type[overviewObj.resource_type]}`}</div>
                        {
                          this.props.relatedBlogs.items.map(item => {
                            return (
                              <div className="related-blog-container" key={item.pageTitle}>
                                <a href={createUrl(item.href, this.props.pagesLinksData)} hrefLang="en"><div className="related-blog-title" dangerouslySetInnerHTML={{ __html: item.bannerHeader }}></div></a>
                              </div>
                            )
                          })
                        }
                      </div>
                    )
                  }
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="visibleForMobile" noGutters>
            {
              this.props.relatedBlogs && this.props.relatedBlogs.items && this.props.relatedBlogs.items.length > 0 && (
                <div>
                  <div className="related-blogs-heading">{overviewObj.resource_type && overviewObj.resource_type === 'White Paper' ? 'Related Resources' : `Related ${resource_type[overviewObj.resource_type]}`}</div>
                  {
                    this.props.relatedBlogs.items.map(item => {
                      return (
                        <div className="related-blog-container" key={item.pageTitle}>
                          <a href={createUrl(item.href, this.props.pagesLinksData)} hrefLang="en"><div className="related-blog-title" dangerouslySetInnerHTML={{ __html: item.bannerHeader }}></div></a>
                          {
                            !campagin && overviewObj.resource_type && overviewObj.resource_type === 'White Paper' && <div className="related-blog-date">Published On: {getFormattedDate(item.date)}</div>
                          }
                        </div>
                      )
                    })
                  }
                </div>
              )
            }
          </Row>
        </Container>
      </div>
    );
  }

  renderPageSeoSchema = () => {
    const data = this.resourceOverviewData || {};
    const overviewObj = data?.primary;
    const isEventPage = data?.resource_type === 'Event'
    if (isEventPage) {
      return <Helmet>
        <script type="application/ld+json">
          {`
              {
                "@context": https://schema.org,
                "@type": "Event",
                "name": ${'s'},
                "startDate": "2025-07-21T19:00-05:00",
                "endDate": "2025-07-21T23:00-05:00",
                "eventAttendanceMode": https://schema.org/OfflineEventAttendanceMode,
                "eventStatus": https://schema.org/EventScheduled,
                "location": {
                  "@type": "Place",
                  "name": "Snickerpark Stadium",
                  "address": {
                    "@type": "PostalAddress",
                    "streetAddress": "100 West Snickerpark Dr",
                    "addressLocality": "Snickertown",
                    "postalCode": "19019",
                    "addressRegion": "PA",
                    "addressCountry": "US"
                  }
                },
                "image": [
                  https://example.com/photos/1x1/photo.jpg,
                  https://example.com/photos/4x3/photo.jpg,
                  https://example.com/photos/16x9/photo.jpg
                 ],
                "description": "The Adventures of Kira and Morrison is coming to Snickertown in a can't miss performance.",
                "offers": {
                  "@type": "Offer",
                  "url": https://www.example.com/event_offer/12345_201803180430,
                  "price": "30",
                  "priceCurrency": "USD",
                  "availability": https://schema.org/InStock,
                  "validFrom": "2024-05-21T12:00"
                },
                "performer": {
                  "@type": "PerformingGroup",
                  "name": "Kira and Morrison"
                },
                "organizer": {
                  "@type": "Organization",
                  "name": "Kira and Morrison Music",
                  "url": https://kiraandmorrisonmusic.com
                }
              }
            `}
        </script>
      </Helmet>
    }

    return null;
  }
}

export default ResourceOverview;
ResourceOverview.propTypes = {
  input: PropTypes.object.isRequired,
  author: PropTypes.object,
};
