import React, { Component } from "react";
import { Col, Row, Container } from "reactstrap";
import { createUrl, getColor } from "../utils/universalHelperFunctions";
import "../styles/resource-description.css";
import RelatedResource from './RelatedResource';
import RelatedResourceCard from './RelatedResourceCard';
import LoginEnablingBlock from '../components/SignUpRestriction';

class ResourceDescription extends Component {
  constructor(props) {
    super(props);
    this.linksData = this.props.pagesLinksData;
    this.state = {
      isSubscribed: false,
      isContentBlocked: false,
      isBlockerDisplayed: false
    };
  }

  componentDidMount() {
    if (localStorage.getItem('isSubscribed')) {
      console.log('isSubscribed: ', )
      this.setState({ isSubscribed: Boolean(localStorage.getItem('isSubscribed')) })
    }
 
    const sectionDescription = this.props.input.primary.resource_section_description;
    let sectionDescriptionContent = sectionDescription ? sectionDescription.raw?.length > 1 ? sectionDescription?.html : sectionDescription.raw ? sectionDescription.raw[0].text : '' : sectionDescription?.html;
    const isContentBlocked = sectionDescriptionContent?.includes('<div id="contentBlocker"></div>');
    isContentBlocked && this.props.hideContent(isContentBlocked);
    
  }

  componentDidUpdate() {
    this.state.isSubscribed && localStorage.setItem('isSubscribed', true);
  }

  onLogin = () => {
    this.setState(Object.assign({ ...this.state }, { isSubscribed: true }))
  }

  onBlockerDisplay = () => {
    this.setState(Object.assign({ ...this.state }, { isBlockerDisplayed: true }))
  }

  render() {
    let isLandingPage = this.props.resourceOverviewData?.primary?.islandingpage;
    let descriptionSection = null;
    let repeatableSection = null;
    let highlighterSection = null;
    let sectionBackgroundColor = "#F1F5F6";
    let sectionFontColor = "#000";
    let HeaderFontColor = "#000";
    let highlighterFontColor = "#000";
    let highlighterColor = "#000";
    console.log('ResourceDescription', this.props);
    let gridLayout = this.props.input.primary.section_partition
      ? getColor(this.props.input.primary.section_partition).split(",")
      : "col-md-9,col-md-3".split(",");
    if (
      this.props.input.primary.hasOwnProperty(
        "resource_section_background_color"
      ) &&
      this.props.input.primary.resource_section_background_color
    ) {
      sectionBackgroundColor = getColor(
        this.props.input.primary.resource_section_background_color
      );
    }
    if (
      this.props.input.primary.hasOwnProperty("resource_section_font_color") &&
      this.props.input.primary.resource_section_font_color
    ) {
      sectionFontColor = getColor(
        this.props.input.primary.resource_section_font_color
      );
    }
    if (
      this.props.input.primary.hasOwnProperty("header_font_color") &&
      this.props.input.primary.header_font_color
    ) {
      HeaderFontColor = getColor(this.props.input.primary.header_font_color);
    }
    if (
      this.props.input.primary.hasOwnProperty("highlighter_font_color") &&
      this.props.input.primary.highlighter_font_color
    ) {
      highlighterFontColor = getColor(
        this.props.input.primary.highlighter_font_color
      );
    }
    if (
      this.props.input.primary.hasOwnProperty("highlighter_color") &&
      this.props.input.primary.highlighter_color
    ) {
      highlighterColor = getColor(this.props.input.primary.highlighter_color);
    }

    let id = "";
    if (this.props.input.primary.hasOwnProperty("breadcrumb_id")) {
      id = this.props.input.primary.breadcrumb_id.text;
    }
    if (
      this.props.input.primary.highlighter_content &&
      this.props.input.primary.highlighter_content.html
    ) {
      highlighterSection = (
        <div
          className="highligher-border"
          style={{ borderColor: highlighterColor }}
        >
          {this.props.input.primary.highlighter_title &&
            this.props.input.primary.highlighter_title.text && (
              <h2 className="highlighter-title">
                {this.props.input.primary.highlighter_title.text}
              </h2>
            )}
          <div
            style={{ color: highlighterFontColor }}
            className={isLandingPage ? `highlighter-content-italic` : `highlighter-content`}
            dangerouslySetInnerHTML={{
              __html:
                this.props.input.primary.highlighter_content.raw.length > 1
                  ? this.props.input.primary.highlighter_content.html
                  : this.props.input.primary.highlighter_content.raw[0].text,
            }}
          />
        </div>
      );
    }
    let displayText = '';
    const sectionDescription = this.props.input.primary.resource_section_description;
    let sectionDescriptionContent = sectionDescription ? (sectionDescription.raw?.length > 1 ? sectionDescription?.html : sectionDescription.raw ? sectionDescription.raw[0].text : '') : sectionDescription?.html;
    //const isContentBlocked = sectionDescriptionContent?.includes('<div id="contentBlocker"></div>');
    //this.setState({isContentBlocked: isContentBlocked});
    console.log('isBlockerDisplayed: ', !this.state.isSubscribed && this.props.isHidden && !this.state.isBlockerDisplayed);
    if (this.state.isSubscribed) {
      displayText = sectionDescriptionContent;
    } else {
      displayText = sectionDescriptionContent?.split('<div id="contentBlocker"></div>')[0];
    }
    descriptionSection = (
      <div
        className={`col-12 ${this.props.input.primary.position_of_description === "Left"
          ? gridLayout[0]
          : `${gridLayout[1]}`
          } description-section`}
      >
        {this.props.input.primary.highlighter_position &&
          this.props.input.primary.highlighter_position === "Top" &&
          this.props.input.primary.highlighter_content &&
          this.props.input.primary.highlighter_content.html && (
            <div style={{ marginBottom: "40px" }}>{highlighterSection}</div>
          )}
        {this.props.input.primary.resource_section_description_in_bold &&
          this.props.input.primary.resource_section_description_in_bold
            .text && (
            <div
              className={`section-content repeatable-items-spacing`}
              dangerouslySetInnerHTML={{
                __html:
                  this.props.input.primary.resource_section_description_in_bold
                    .raw.length > 1
                    ? this.props.input.primary
                      .resource_section_description_in_bold.html
                    : this.props.input.primary
                      .resource_section_description_in_bold.raw[0].text,
              }}
            />
          )}
        {this.props.input.primary.resource_section_description && (
          <div
            className={`section-content`}
            dangerouslySetInnerHTML={{
              __html:
              displayText
            }}
          />
        )}
        {!this.state.isSubscribed && this.props.isHidden && < LoginEnablingBlock onLogin={this.onLogin} onBlockerDisplay={this.onBlockerDisplay}/>}
        {!this.state.isSubscribed && this.props.isHidden && this.props.input.primary.resource_section_image &&
          this.props.input.primary.resource_section_image.url && this.props.input.primary.image_position === 'Bottom' && (
            <p class=" block-img">
              <img
                src={this.props.input.primary.resource_section_image.url}
                alt=""
                copyright=""
              />
            </p>
          )}
        {(this.state.isSubscribed && this.props.isHidden) || !this.props.isHidden   && this.props.input.primary.resource_section_description_after_image &&
          this.props.input.primary.resource_section_description_after_image
            .raw && (
            <div
              className={`section-content`}
              dangerouslySetInnerHTML={{
                __html:
                  this.props.input.primary
                    .resource_section_description_after_image.raw.length > 1
                    ? this.props.input.primary
                      .resource_section_description_after_image.html
                    : this.props.input.primary
                      .resource_section_description_after_image.raw[0].text,
              }}
            />
          )}
        {(this.state.isSubscribed && this.props.isHidden) || !this.props.isHidden  && this.props.input.primary.highlighter_position &&
          this.props.input.primary.highlighter_position !== "Top" &&
          this.props.input.primary.highlighter_content &&
          this.props.input.primary.highlighter_content.html && (
            <div style={{ padding: "30px 0" }}>{highlighterSection}</div>
          )}
      </div>
    );


    repeatableSection = (
      <Col
        className={`col-12 ${this.props.input.primary.position_of_description === "Left"
          ? `${gridLayout[1]}`
          : `${gridLayout[0]}`
          } ${gridLayout[0] === "col-md-6" ? "section-font-6-6" : "section-font-9-3"
          } section-spacing-for-mobile`}
      >

        {this.props.input.primary.resource_section_image &&
          this.props.input.primary.resource_section_image.url && this.props.input.primary.image_position === 'Right' && (
            <p class=" block-img">
              <img
                src={this.props.input.primary.resource_section_image.url}
                alt=""
                copyright=""
              />
            </p>
          )}

        {this.props.input.primary.repeatable_section_heading &&
          this.props.input.primary.repeatable_section_heading.text && (
            <h2
              style={{ color: HeaderFontColor }}
              className={`heading-for-repeatable-section ${gridLayout[0] === "col-md-6"
                ? "section-font-header-6-6"
                : "section-font-header-9-3"
                }`}
            >
              {this.props.input.primary.repeatable_section_heading.text}
            </h2>
          )}
        {this.props.input.items &&
          this.props.input.items.length > 0 &&
          this.props.input.items.map((item, index) => {
            if (item.description_subheading && item.description_subheading.text) {
              return (<Row key={index} style={{ paddingTop: '30px' }}>
                {
                  item.summary_position === 'Top' && <><Col sm="12" md="12" lg="12">
                    <span className="subTitle">{item.description_subheading.text}</span>
                  </Col>
                    <Col sm="12" md="12" lg="12" >
                      <div className="repeatable-sub-description" dangerouslySetInnerHTML={{
                        __html:
                          item.summary.raw.length > 1
                            ? item.summary.html
                            : item.summary.raw[0].text,
                      }}></div>
                    </Col>
                  </>
                }
                {item.position === 'Left' ? <Col sm="12" md="6" lg="6">
                  <span className="subTitle">{item.description_subheading.text}</span>
                  <div className="repeatable-sub-description" dangerouslySetInnerHTML={{
                    __html:
                      item.repeatable_section_description.raw.length > 1
                        ? item.repeatable_section_description.html
                        : item.repeatable_section_description.raw[0].text,
                  }}></div>
                </Col> : <></>}
                <Col sm="12" md="6" lg="6">
                  {item.related_resource && <RelatedResourceCard related_resource_background_color={'rgba(28,53,94,0.9)'} {...item.related_resource} />}
                </Col>
                {item.position === 'Right' ? <Col sm="12" md="6" lg="6">
                  {!item.summary_position || item.summary_position === 'Bottom' ? <span className="subTitle">{item.description_subheading.text}</span> : <></>}
                  <div className="repeatable-sub-description" dangerouslySetInnerHTML={{
                    __html:
                      item.repeatable_section_description.raw.length > 1
                        ? item.repeatable_section_description.html
                        : item.repeatable_section_description.raw[0].text,
                  }}></div>
                </Col> : <></>}
                {
                  item.summary_position === 'Bottom' ? <Col sm="12" md="12" lg="12">
                    <div className="repeatable-sub-description" dangerouslySetInnerHTML={{
                      __html:
                        item.summary.raw.length > 1
                          ? item.summary.html
                          : item.summary.raw[0].text,
                    }}></div>
                  </Col> : <></>
                }
              </Row>);
            }
            // if (item.repeatable_section_title &&
            //   item.repeatable_section_title.text) {
              let linkContent =
                item.repeatable_section_title &&
                  item.repeatable_section_title.text
                  ? item.repeatable_section_title.text
                  : "";
              if (
                item.repeatable_section_link_of_title &&
                item.repeatable_section_link_of_title.url
              ) {
                let regEx = new RegExp(" - ", "ig");
                if (linkContent.match(regEx) != null) {
                  let linkContentArr = linkContent.split(regEx);
                  if (linkContentArr && linkContentArr.length > 0) {
                    linkContentArr[0] = linkContentArr[0].bold();
                    linkContent = linkContentArr.join(". ");
                  }
                }
              }
              return (
                <div className="repeatable-items-spacing" key={index}>
                  {item.repeatable_section_link_of_title &&
                    item.repeatable_section_link_of_title.url ? (
                    <a
                      href={createUrl(
                        item.repeatable_section_link_of_title.url,
                        this.linksData
                      )}
                      className="repeatable-title"
                      style={{ color: sectionFontColor }}
                      target="_blank"
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: linkContent,
                        }}
                        style={{ paddingRight: "8px" }}
                      />
                      <i className="fas fa-external-link-alt new-link-icon"></i>
                    </a>
                  ) : (
                    item.repeatable_section_title &&
                    item.repeatable_section_title.text && (
                      <h3 className="repeatable-title">
                        {item.repeatable_section_title.text}
                      </h3>
                    )
                  )}
                  {item.repeatable_section_description && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                          item.repeatable_section_description.raw ? (item.repeatable_section_description.raw.length > 1
                              ? item.repeatable_section_description.html
                              : item.repeatable_section_description.raw[0].text) : '',
                        }}
                      />
                    )}
                </div>
              );
            //}
            

          })}
      </Col>
    );

    const { resource_section_heading = {}, position_of_description, related_resource, related_resource_background_color } = this.props.input.primary;
    const { html: resourceHeadingHtml } = resource_section_heading;
    if (!position_of_description && !resourceHeadingHtml) {
      return <></>
    }

    return (
      ((this.props.input.primary.is_protected && this.state.isSubscribed) || !this.props.input.primary.is_protected) &&  <div
        className={`resource-description-container`}
        style={{ color: sectionFontColor, background: sectionBackgroundColor }}
        id={id}
      >
        <Container style={{ paddingTop: '40px', paddingBottom: '40px'}}>
          <Row noGutters>
            {resourceHeadingHtml && (
              <h2
                style={{ color: HeaderFontColor }}
                className={`section-heading col-12`}
                dangerouslySetInnerHTML={{
                  __html:
                    this.props.input.primary.resource_section_heading.raw
                      .length > 1
                      ? this.props.input.primary.resource_section_heading.html
                      : this.props.input.primary.resource_section_heading
                        .raw[0].text,
                }}
              />
            )}
          </Row>
          <Row noGutters>
            {position_of_description === "Left" ? (
              <>
                {descriptionSection}
                {(this.state.isSubscribed && this.props.isHidden) || !this.props.isHidden  && repeatableSection}
              </>
            ) : position_of_description === "Right" ? (
              <>
                {(this.state.isSubscribed && this.props.isHidden) || !this.props.isHidden  && repeatableSection}
                {descriptionSection}
              </>
            ) : null}
            {(this.state.isSubscribed && this.props.isHidden) || !this.props.isHidden  && related_resource && <RelatedResource related_resource_background_color={related_resource_background_color} {...related_resource} />}
          </Row>
        </Container>
      </div>
    );
  }
}

export default ResourceDescription;
